<script setup lang="ts">
</script>
<template>
  <div
    class="top"
    id="top"
  >
    <div class="top-content">
      <div class="top-content-left">
        <img
          class="top-content-left-image"
          src="@/assets/images/Artboard-31.webp"
          alt="">
      </div>
      <div class="top-content-text">
        <h1 class="top-content-text-title">Таргетированная <br>реклама</h1>
        <div class="top-content-text-plates">
          <div class="top-content-text-plates-plate">
            <img
              class="top-content-text-plates-plate-image"
              src="@/assets/images/svg/users-alt.svg"
              alt="">
            <h3 class="top-content-text-plates-plate-title">96%</h3>
            <p class="top-content-text-plates-plate-description">пользователей <br>
              рунета — охват <br>
              аудитории <br>
              проектов</p>
          </div>
          <div class="top-content-text-plates-plate">
            <img
              class="top-content-text-plates-plate-image"
              src="@/assets/images/svg/megaphone.svg"
              alt="">
            <h3 class="top-content-text-plates-plate-title">400</h3>
            <p class="top-content-text-plates-plate-description">таргетингов для <br>
              выбора целевой <br>
              аудитории и <br>
              настройки <br>
              рекламы</p>
          </div>
          <div class="top-content-text-plates-plate">
            <img
              class="top-content-text-plates-plate-image"
              src="@/assets/images/svg/money.svg"
              alt="">
            <h3 class="top-content-text-plates-plate-title">600р</h3>
            <p class="top-content-text-plates-plate-description">составляет <br>
              стартовый <br>
              бюджет <br>
              рекламной <br>
              кампании</p>
          </div>
        </div>
        <p class="top-content-text-description">
          Вы запускаете новую акцию, распродажу или новую <br>
          систему скидок, и вам нужно, чтобы об этом узнали <br>
          тысячи новых людей? Хотите повысить уровень <br>
          продаж? Вам поможет таргетированная реклама в <br>
          социальных сетях!
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: get-vw(1150px);
  margin-left: auto;
  margin-right: auto;
}
.top {
  &-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: get-vw(70px);
    &-left {
      width: 50%;
      &-image {
        margin-left: get-vw(-55px);
        height: 110%;
        width: 110%;
      }
    }
    &-text {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-left: get-vw(50px);
      &-title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 800;
        font-size: get-vw(50px);
        line-height: get-vw(65px);
        color: #000000;
        padding-left: get-vw(15px);
      }
      &-plates {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: get-vw(85px);
        &-plate {
          display: flex;
          flex-direction: column;
          width: get-vw(130px);
          align-items: center;
          text-align: center;
          &-image {
            width: 55%;
            height: 55%;
          }
          &-title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: get-vw(30px);
            line-height: 85.52%;
            text-align: center;
            color: #000000;
            margin-top: get-vw(75px);
          }
          &-description {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: get-vw(15px);
            line-height: 140%;
            text-align: center;
            color: #1C1C1C;
            margin-top: get-vw(15px);
          }
        }
      }

      &-description {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: get-vw(18px);
        line-height: get-vw(28px);
        color: #000000;
        padding-left: get-vw(20px);
        margin-top: get-vw(70px);
        white-space: nowrap;
      }
    }
  }
}
</style>
